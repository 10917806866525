import { createTheme, responsiveFontSizes } from "@mui/material"
import palette from "./palette"
import components from "./components"
import typography from "./typography"
import breakpoints from "./breakpoints"

const { shadows } = createTheme()

shadows.push("0px 4px 20px rgba(64, 86, 120, 0.1)")
shadows.push("4px 4px 20px rgba(87, 103, 109, 0.08)")
shadows.push("4px 4px 15px rgba(1, 69, 98, 0.15)")

const theme = createTheme({
  palette,
  components,
  typography,
  breakpoints,
  shadows,
  spacing: 8,
  shape: {
    borderRadius: 6,
  },
  topBarHeight: 52,
  mobileTopBarHeight: 40,
})

export default responsiveFontSizes(theme, {
  factor: 6,
  breakpoints: ["xs", "sm", "md", "lg"],
})
