const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1040,
    xl: 1410,
    xxl: 1700,
    menu: 1220,
  },
}

export default breakpoints
