const white = "#FFFFFF"
const black = "#010001"

const palette = {
  black,
  white,
  primary: {
    contrastText: "#E5E5E5",
    light: "#F1F7FF",
    lighter: "#9CC2FE",
    main: "#3985FD",
    darker: "#2E6ACA",
    dark: "#122250",
  },
  secondary: {
    contrastText: white,
    main: "#558811",
  },
  error: {
    contrastText: white,
    main: "#fc2862",
  },
  warning: {
    main: "#ECA72C",
  },
  info: {
    contrastText: white,
    main: "#008EED",
  },
  success: {
    contrastText: white,
    main: "#558811",
  },
  button: {
    yellow: "rgba(255, 230, 0, 0.2)",
    green: "rgba(58, 190, 87, 0.2)",
    blue: "#F1F7FF",
    orange: "rgba(255, 145, 43, 0.2)",
  },
  text: {
    primary: "#000B12",
    // secondary: transparentize(0.38, "#000B12"),
    secondary: "#545454",
    tertiary: "#9d9d9d",
    placeholder: "#5f6368",
    link: "#008EED",
  },
  background: {
    default: "#fff",
    paper: white,
    block: "#F1F7FF",
    gray: "#FAFAFA",
  },
  divider: "#e5e5e5",
  colorTag: {
    banking: "#3152C6",
    distribution: "#4D9AC6",
    microfinancing: "#3ABE57",
    production: "#DA6D6D",
    publicSector: "#FF912B",
    specialProjects: "#FFE600",
  },
}

export default palette
