import palette from "./palette"

const typography = {
  fontFamily: "Arial",
  fontSize: 16,
  h1: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "2.75rem",
    lineHeight: 1.3,
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "2.5rem",
    lineHeight: 1.4,
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "2.25rem",
    lineHeight: 1.3,
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "2rem",
    lineHeight: 1.4,
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "1.75rem",
    lineHeight: 1.4,
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 400,
    // fontSize: "1.625rem",
    fontSize: "1.125rem",
    lineHeight: 1.3,
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: "1rem",
    fontWeight: 400,
  },
  subtitle2: {
    color: palette.text.secondary,
    fontSize: "0.9rem",
    fontWeight: 400,
    lineHeight: 1.35,
  },
  body1: {
    color: palette.text.primary,
    fontSize: "1.375rem",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    color: palette.text.secondary,
    fontSize: "1.125rem",
    fontWeight: 400,
    lineHeight: 1.4,
  },
  body3: {
    color: palette.text.primary,
    fontSize: "1rem",
    fontWeight: 300,
    lineHeight: 1.5,
  },
  button: {
    color: palette.text.primary,
    fontSize: "0.9rem",
    fontWeight: 500,
    textTransform: "none",
  },
  caption: {
    color: palette.text.primary,
    fontSize: "0.875rem",
    lineHeight: "21px",
  },
  overline: {
    color: palette.text.secondary,
    fontSize: "1.125rem",
    fontWeight: 600,
    letterSpacing: "0.33px",
    lineHeight: 1.3,
  },
}

export default typography
