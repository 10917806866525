import React from "react"
import { CssBaseline, ThemeProvider } from "@mui/material"

import { Helmet } from "react-helmet"

import "./src/css/typography.css"
import theme from "./src/theme"

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          as="image"
          href="/images/lightLogo.svg"
          type="image/svg+xml"
        />
        <link
          rel="preload"
          as="image"
          href="/images/darkLogo.svg"
          type="image/svg+xml"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {element}
      </ThemeProvider>
    </>
  )
}
