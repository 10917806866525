const MuiTextField = {
  styleOverrides: {
    root: {
      "& .MuiOutlinedInput-root": {
        "& input": {
          boxSizing: "border-box",
          borderRadius: `4px`,
          height: 54,
          border: "1px solid #E5E5E5",
        },
      },
    },
  },
}

export default MuiTextField
