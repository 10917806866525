import { createTheme } from "@mui/material"
import palette from "../palette"

const defaultTheme = createTheme()

const MuiButton = {
  styleOverrides: {
    root: {
      borderRadius: "8px",
      fontWeight: 600,
      lineHeight: 1.4,
      padding: "15px 24px 12px !important",
      transition: defaultTheme.transitions.create(
        ["background-color", "box-shadow", "border", "color"],
        {
          duration: defaultTheme.transitions.duration.short,
        }
      ),
    },
    colorInherit: {
      background: palette.background.block,
    },
    sizeSmall: {
      // padding: "8px 16px !important",
      // fontSize: "1rem",
      padding: "15px 24px 12px !important",
      fontWeight: 600,
    },
    sizeMedium: {
      // padding: "12px 32px 12px 32px !important",
      // fontSize: "1.25rem",
      padding: "15px 24px 12px !important",
      fontWeight: 600,
    },
    sizeLarge: {
      minWidth: 64,
      // padding: "10px 30px !important",
      padding: "15px 24px 12px !important",
      fontSize: "1.25rem",
    },
    contained: {
      color: "white",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },

    text: {
      "&:hover": {
        color: palette.primary.main,
        background: "transparent",
      },
    },
    startIcon: {
      marginRight: "5px",
    },
    endIcon: {
      marginLeft: "5px",
    },
  },
}

export default MuiButton
